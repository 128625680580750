import React, { useCallback, useEffect, useState } from "react"
import { Button, Media, Row, Col, Modal } from 'react-bootstrap'
import { Link, navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from 'react-bootstrap/Container'
import Footer from '../components/footer'
import { bdstatistics, apiURL, mqStatistics, GetQueryString } from '../components/census'
import CommonFlex from '../components/commonFlex'
import CustomerInfos from './../components/CustomerInfos'

import '../components/animate-index.css'

// import anli from './../assets/AIRIOT产品简介.pdf'
// const anli = require('./../assets/AIRIOT产品简介.pdf')

import './../assets/css/engine.css'
import './../components/media.css'
import './../assets/css/index.css'
import './../components/release.css'
import './../assets/css/ppt.css'

const PPTPage = () => {
  const [ hash, setHash ] = React.useState(null)
  const [ showModal, setShowModal ] = React.useState({ show: false, url: null, name: null })
  const [ show, setShow ] = React.useState(false)

  React.useEffect(() => {
    bdstatistics()
    mqStatistics()
  }, [])

  React.useEffect(() => {
    if(typeof window !== "undefined") {
      let windowHeight = document.documentElement.clientHeight

      window.addEventListener('scroll', (event) => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        let animate_box = document.getElementsByClassName('product-media');

        let animate_box_left = document.getElementsByClassName('product-left-animate');
        let animate_box_right = document.getElementsByClassName('product-right-animate');

        let box = document.getElementsByClassName('img-bg-edit')
        let animate_box_media = document.getElementsByClassName('product-media')
 
        for(let i=0; i<animate_box.length;i++) {
          if(animate_box[i].offsetTop < (windowHeight + scrollTop)) {
            animate_box_left[i].style.animation = `fadeInLeft 500ms linear`
            animate_box_right[i].style.animation = `fadeInRight 500ms linear`
          } else {
            animate_box_left[i].style.animation = `fadeInRight 500ms linear`
            animate_box_right[i].style.animation = `fadeInLeft 500ms linear`
          }
        }

      })
      
      if(window.location.hash && window.location.hash.indexOf('#') >-1)  {
        setHash(window.location.hash.substr(window.location.hash.indexOf('#')+1))
      }
    }
  },  [])

  React.useEffect(() => {
    if(typeof window !== "undefined") {
      // 跳转到指定位置
      let product_media = document.getElementsByClassName('product-media');
      if(hash) {
        for(let i=0; i<product_media.length;i++) {
          let name = product_media[i].getAttribute('name')
          if(name == hash) {
            window.scrollTo({
              top: product_media[i].offsetTop -150
            })
          }
        }
      }
    }
  }, [ hash ])

  const handleUploadFile = (url, fileName) => {
    let a = document.createElement('a')
    a.href = `${apiURL}${url}`
    a.download = fileName
    a.click();
  }

  const downloadUrlFile = (url, fileName) => {
    return new Promise((resolve, reject) => {
      var blob = null
      var xhr = new XMLHttpRequest()
      xhr.open("GET", url)
      xhr.responseType = "blob"
      // 加载时处理
      xhr.onload = () => {
        // 获取返回结果
        blob = xhr.response
        let file = new File([blob], fileName, { type: 'application/pdf; charset=UTF-8' })
        // 返回结果
        resolve(file)
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(file)
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        return 
      }
      xhr.onerror = (e) => {
        reject(e)
      }
      // 发送
      xhr.send()
    })
  }

  return (
    // <div className="product-page engine-page">
    //   <div className='home-bg home-index engine-homa  engine-home-bg engine-home-4'>
    //     <Layout>
    //         <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
    //           <div className="engine-plat-common">
    //             <div className="plat-jumbotron-common">
    //                 <div className="jumbotron-left-common">
    //                     <div className="jumbotron-title-common">
    //                       <img src={require('./../images/airiot.png')} className="engine-img-left"/>
    //                       4.0版本
    //                       <span className="engine-jumbotron-span">重磅发布</span>
    //                     </div>
    //                     <div className="jumbotron-desc-common">{engineObj[name].bannerData.desc}</div>
    //                 </div>
    //             </div>
    //             <div className="jumbotron-right-common" >
    //               <img className={`jumbotron-img ${engineObj[name].bannerData.key}`} src={engineObj[name].bannerData.banner}></img>
    //             </div>
    //           </div>
    //     </Layout>
    // </div>
    <div>
      <div className="home-bg contact-box" style={{ background: 'none', height: '3.5rem' }}>
        <Layout>
          <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
        </Layout>
      </div>
      <Container className="content-box product-container">
        <CommonFlex style={{ display: 'block', textAlign: 'left', justifyContent: 'center' }} animate="animate1" position="left" title="公司简介" desc="航天科技控股集团股份有限公司是军工央企控股的军民融合型高科技上市公司1998年成立，1999年主板上市（000901)，业务聚焦航天应用、物联网、汽车电子围绕一横三纵的业务布局推动产业发展。"></CommonFlex>
        <div className="ppt">
          <div className="ppt-wrap">
            <div className="ppt-cut">
              {/* <iframe className="ppr-iframe" id="ppt" src={require('./../assets/公司简介.pdf')} ></iframe> */}
              <iframe className="ppr-iframe" id="ppt" src="/ppt/web/viewer.html" ></iframe>
            </div>
          </div>
        </div>
        {/* <div style={{ textAlign: 'center' }}>
          <Button variant="primary" className="engine-button-product" 
          // onClick={() => {
          //   downloadUrlFile(`${apiURL}/uploads/_196d08febd.pdf`, 'AIRIOT公司简介')
          // }}
          onClick={() => { setShowModal({ ...showModal, show: true, url: `${apiURL}/uploads/_7aef67a267.pdf`, name: 'AIRIOT公司简介' }) }} 
          >立即下载</Button>
        </div> */}

        <CommonFlex product_message={true} style={{ display: 'block', textAlign: 'left', justifyContent: 'center' }} animate="animate1" position="left" title="AIRIOT产品介绍" desc="AIRIOT是一款低代码快速搭建物联网系统的平台型产品，用户使用该平台，能快速构建稳定可靠的物联网系统，节省时间及人力成本。平台提供了丰富的功能及完备的组件，同时支持灵活的二次开发，具有低成本、高效率、易操作，可扩展的特点。"></CommonFlex>
        <div className="ppt">
          <div className="ppt-wrap">
            <div className="ppt-cut"> 
              {/* <iframe className="ppr-iframe" id="ppt" src={require('./../assets/产品介绍.pdf')} ></iframe> */}
              <iframe className="ppr-iframe" id="ppt" src="/ppt/product/viewer.html" ></iframe>
            </div>
          </div>
        </div>
        {/* <div style={{ textAlign: 'center' }}>
          <Button variant="primary" className="engine-button-product" onClick={() => {
            // downloadUrlFile(`${apiURL}/uploads/AIRIOT_566d4f50ea.pdf`, 'AIRIOT产品介绍')
            setShowModal({ ...showModal, show: true, url: `${apiURL}/uploads/_ce9e344295.pdf`, name: 'AIRIOT产品介绍' })
          }}>立即下载</Button>
        </div> */}

        <CommonFlex style={{ display: 'block', textAlign: 'left', justifyContent: 'center' }} animate="animate1" position="left" title="行业案例" desc="服务1000+项目数十个垂直行业，一站式全功能平台赋能物联网应用全场景落地。平均缩短项目实施周期70%，节省50%以上人力成本。"></CommonFlex>
        <div className="ppt">
          <div className="ppt-wrap">
            <div className="ppt-cut" style={{ height: '645px'}}>
              {/* <iframe className="ppr-iframe" id="ppt" src={require('./../assets/AIRIOT行业合集.pdf')} ></iframe> */}
              <iframe className="ppr-iframe" id="ppt" src="/ppt/industry/viewer.html" ></iframe>
            </div>
          </div>
        </div>
        {/* <div style={{ textAlign: 'center' }}>
          <Button variant="primary" className="engine-button-product" onClick={() => {
            // downloadUrlFile(`${apiURL}/uploads/AIRIOT_6fee77b244.pdf`, 'AIRIOT行业合集')
            setShowModal({ ...showModal, show: true, url: `${apiURL}/uploads/_bfe7855036.pdf`, name: 'AIRIOT行业合集' })
          }}>立即下载</Button>
        </div> */}

        <CommonFlex style={{ display: 'block', textAlign: 'left', justifyContent: 'center' }} animate="animate1" position="left" title="生态合作" desc="面向行业上下游合作伙伴、客户、高校、协会打造智慧物联生态圈，共享业务生态联盟、智慧物联商城、物联网软件训练营认证培训等生态资源。"></CommonFlex>
        <div className="ppt">
          <div className="ppt-wrap">
            <div className="ppt-cut" style={{ height: '645px'}}>
              {/* <iframe className="ppr-iframe" id="ppt" src={require('./../assets/生态合作.pdf')} ></iframe> */}
              <iframe className="ppr-iframe" id="ppt" src="/ppt/ecology/viewer.html" ></iframe>
            </div>
          </div>
        </div>
        {/* <div style={{ textAlign: 'center' }}>
          <Button style={{ marginBottom: '7.5rem' }} variant="primary" className="engine-button-product" onClick={() => {
            // handleUploadFile('/uploads/_f7a535ab83.pdf', 'AIRIOT生态合作')
            // downloadUrlFile(`${apiURL}/uploads/_f7a535ab83.pdf`, 'AIRIOT生态合作')
            setShowModal({ ...showModal, show: true, url: `${apiURL}/uploads/_c68156993c.pdf`, name: 'AIRIOT生态合作' })
          }}>立即下载</Button>    
        </div> */}
      </Container>
      <Footer></Footer>
      {/* <Modal
        show={showModal.show}
        onHide={() => setShowModal({ ...showModal, show: false })}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        className="down_modal"
      >
        <Modal.Body>
          <CustomerInfos type="ppt-down" downloadUrlFile={downloadUrlFile} name={showModal?.name} url={showModal.url} 
            changeModal={() => { setShowModal({ ...showModal, show:false }); setShow(true) }}/>
        </Modal.Body>
      </Modal> */}
    </div>
  )
}

export default PPTPage

